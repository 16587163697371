import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Tại sao bạn nên tham gia khoá học giao dịch Future?',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI4urk2NzEJzgEONOAfTqXDrjlyAeQbOX4lQ&s"}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Tiết kiệm thời gian
                  </h4>
                  <p className="m-0 text-sm">
                    Thay vì bạn tự mình nghiên cứu và tự giao dịch thì việc đăng kí khoá học giao dịch Future sẽ giúp bạn tiết kiệm thời gian hơn và giúp bạn nhanh đạt được thành công trong giao dịch và sớm đạt được tự do tài chính.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI4urk2NzEJzgEONOAfTqXDrjlyAeQbOX4lQ&s"
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Kiếm thêm thu nhập Online
                  </h4>
                  <p className="m-0 text-sm">
                    Ngoài công việc chính mà bạn đang làm, thì sau khi tham gia khoá học bạn sẽ sở hữu cho mình thêm một công việc phụ giúp bạn kiếm thêm thu nhập, như là nguồn thu nhập thứ hai.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI4urk2NzEJzgEONOAfTqXDrjlyAeQbOX4lQ&s"
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Chi phí phù hợp
                  </h4>
                  <p className="m-0 text-sm">
                    Việc bỏ ra một số tiền như vậy giúp bạn rất nhiều trong việc giảm thua lỗ trong giao dịch tài chính. Hầu hết những người tham gia giao dịch ban đầu mất rất nhiều tiền "học phí" cho thị trường với những lỗi rất cơ bản.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI4urk2NzEJzgEONOAfTqXDrjlyAeQbOX4lQ&s"
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Hỗ trợ tín hiệu
                  </h4>
                  <p className="m-0 text-sm">
                    Được tham gia vào nhóm hỗ trợ tín hiệu của học viện, nhận các thông tin về giao dịch thị trường cũng như các tài liệu về giao dịch,..
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI4urk2NzEJzgEONOAfTqXDrjlyAeQbOX4lQ&s"
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Công cụ chỉ báo
                  </h4>
                  <p className="m-0 text-sm">
                    Được nhận bộ công cu chỉ báo tín hiệu được phát triển dựa trên công nghệ AI do đội ngũ lập trình viên của học viện phát triển.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI4urk2NzEJzgEONOAfTqXDrjlyAeQbOX4lQ&s"
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Quản trị rủi ro
                  </h4>
                  <p className="m-0 text-sm">
                    Được nhận bộ tài liệu về cách quản trị rủi ro và cách sống sót trong thị trường tài chính để hướng tới con đường tự do tài chính.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;