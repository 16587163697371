import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
const propTypes = { ...SectionTilesProps.types }
const defaultProps = { ...SectionTilesProps.defaults }

const telegramLink = () => {
  return "https://t.me/huancapital"
}

const getNextMonth = () => {
  const month = new Date().getMonth() + 2
  if (month > 12) {
    return 1
  }
  return month
};

const renderFutureResult = () => {
  return (
    <div className="tiles-item reveal-from-bottom">
      <div className="tiles-item-inner">
        <div className="features-tiles-item-header">
          <div className="features-tiles-item-image mb-16">
            <Image src='https://i.ibb.co/RzptzGL/Screenshot-2023-07-06-at-21-38-17.png' alt='Feature' />
          </div>
          <div className="features-tiles-item-image mb-16">
            <Image src='https://i.ibb.co/P5xrPHg/Screenshot-2023-07-11-at-21-00-52.png' alt='Feature' />
          </div>
          <div className="features-tiles-item-image mb-16">
            <Image src='https://i.ibb.co/d6Zxq5R/Screenshot-2023-07-11-at-21-01-33.png' alt='Feature' />
          </div>
          <div className="hero-content">
            <div className="container">
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile target='_blank' href={telegramLink()}>
                    Học Thử <strong> (Miễn Phí)</strong>
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup>
                  <label tag="a" color="primary" wideMobile target='_blank' href={telegramLink()}>
                    Qua tháng {getNextMonth()} giá tăng <strong>1200$</strong>
                  </label>
                </ButtonGroup> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  const sectionHeader = {
    title: 'Các giao dịch thành công',
    paragraph: ""
  };

  const sectionHeade1 = {
    title: 'Người Thành Công Là Người Ra Quyết Định Nhanh',
    paragraph: 'Chúc bạn luôn thành công trong giao dịch tài chính'
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            {renderFutureResult()} {renderFutureResult()} {renderFutureResult()}
          </div>
        </div>
        <SectionHeader data={sectionHeade1} className="center-content" />
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;